<template>
  <ul>
      <li class="card">
          <p class="top">
              <span style="color:#666666;">订单号：{{order.order_sn}}</span>
              <span :style="{color: color}">{{ _getOrderStatusText()}}</span>
          </p>
          <div class="middle">
              <img :src="order.get_train.banner" alt="banner">
              <div>
                  <p style="color:#333333;">
                      {{order.get_train.title}}
                  </p>
                  <p class="price">
                      ¥{{order.total_fee}}
                  </p>
              </div>
          </div>
          <div class="hr" v-if="order.is_paid == 0 && order.status != 2"></div>
          <div class="bottom" v-if="order.is_paid == 0 && order.status != 2">
              <button class="cancel btn" @click="_cancelOrder">取消订单</button>
              <button class="pay btn" @click="_gotoPay">立即付款</button>
          </div>
      </li>
  </ul>
</template>

<script>
import { cancelOrder, payOrder } from '@/util/api'
const statusText = ['正常', '退款', '未支付取消', '审核中', '审核失败', '部分审核', '已审核', '已完成']
export default {
  name: "order-item",
  props: {
    order: {
      type: Object,
      default: {},
    },
  },
  data () {
      return {
          color: '#EF6D1A'
      }
  },
  methods: {
    _cancelOrder () {
        // status: 0 正常 1 退款 2 未支付取消 3审核中 4审核失败 5部分审核 6已审核 7已完成
        cancelOrder({ id: this.order.id }).then(() => {
            this.$emit('orderUpdate')
        })
    },
    _getOrderStatusText () {
        if (this.order.is_paid == 0) {
            if (this.order.status == 0) {
                this.color = '#EF6D1A'
                return '待付款'
            } else if (this.order.status == 2) {
                this.color = '#666666'
                return '已取消'
            }
        } else {
            this.color = this.order.status == 2 ? '#666666' : '#1A7EEF'
            return statusText[this.order.status]
        }
    },
    _gotoPay () {
        payOrder({ order_id: this.order.id }).then(res => {
            if (res.data.trade_type === 'MWEB') {
                location.href = res.data.url
            } else if (res.data.trade_type === 'JSAPI') {
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                    }
                } else {
                    onBridgeReady(res.data)
                }
            }
        })
    },
    _onBridgeReady(wxData) {
        WeixinJSBridge.invoke('getBrandWCPayRequest', wxData, function(res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            } else {
                Toast.fail(res.err_msg)
            }
        });
    },
  }
}
</script>

<style scoped>
.btn{
    border: none;
    border-radius: .3rem;
    height: .6rem;
    font-size: .25rem;
    width: 2rem;
    margin-left: .5rem;
}
.pay{
    background: linear-gradient(180deg, #FFA936 0%, #EB732E 100%);
    color: #fff;
}
.cancel {
    border: 1px solid #999999;
}
.bottom{
    text-align: right;
    margin-top: .2rem;
}
.hr{
    height: 1px;
    background: #eee;
    margin-top: .2rem;
}
.price{
    color: #ED1818;
    margin-top: .8rem;
}
.top{
    display: flex;
    justify-content: space-between;
    margin-bottom: .2rem;
}
.middle{
    display: flex;
    justify-content: space-between;
}
.middle img{
    width: 3.3rem;
}
.middle div{
    width: 3.3rem;
}
.card{
    background: #fff;
    width: 100%;
    /* height: 4.2rem; */
    margin: .2rem auto;
    padding: .2rem;
    box-sizing: border-box;
    color: #999;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}
</style>
