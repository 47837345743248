<template>
    <div class="order">
        <van-tabs v-model="active" color="#1A7EEF" title-active-color="#1A7EEF" title-inactive-color="#666666">
            <van-tab title="全部订单"><order></order></van-tab>
            <van-tab title="待付款"><order paid="0"></order></van-tab>
        </van-tabs>
    </div>
</template>

<script>
import Order from '@/components/Order.vue'

export default {
    components: {
        Order
    },
    data (){
        return {
            active: 0
        }
    }
}
</script>

<style scoped>
.btn{
    border: none;
    border-radius: .2rem;
    height: .7rem;
    font-size: .25rem;
    width: 2rem;
    margin-left: .5rem;
}
.pay{
    background: orange;
    color: #fff;
}
.bottom{
    text-align: right;
    margin-top: .2rem;
}
.hr{
    height: 1px;
    background: #eee;
    margin-top: .2rem;
}
.price{
    margin-top: .8rem;
}
.top{
    display: flex;
    justify-content: space-between;
}
.middle{
    display: flex;
    justify-content: space-between;
}
.middle img{
    width: 3.3rem;
}
.middle div{
    width: 3.3rem;
}
.order{
    padding: .2rem;
    background: #eee;
    height: 100vh;
    font-size: .3rem;
}
.card{
    background: #fff;
    width: 7.2rem;
    height: 5rem;
    margin: 0 auto;
    padding: .1rem;
    box-sizing: border-box;
    color: #999;
}
</style>