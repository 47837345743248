<template>
    <div>
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="已经到底啦~"
            @load="getOrders"
            >
            <div v-for="order in dataList" :key="order.id">
                <order-item :order="order" @orderUpdate="getOrders"></order-item>
            </div>
        </van-list>
        
    </div>
</template>

<script>
import Item from '@/components/Item'
import { orders } from '@/util/api'

export default {
    name: 'Order',
    components: {
        'order-item': Item
    },
    props: {
        paid: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            active: 1,
            dataList: [],
            pageNum: 1,
            pageSize: 10,
            loading: false,
            finished: false
        }
    },
    created () {
        this.getOrders()
    },
    methods: {
        getOrders () {
            orders({ is_paid: this.paid }).then(res => {
                this.loading = false
                this.finished = true
                this.dataList = res.data || []
            })
        }
    }
}
</script>

<style scoped>

</style>